import { StaticQuery, graphql } from "gatsby"

import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import React from "react"
import striptags from "striptags"

function SEO({
  hasFaq,
  canonical,
  location,
  description,
  lang,
  meta,
  keywords,
  title,
  shortDesc,
  image,
  url,
  loadClutch,
  blogPostData, // Added prop for blog post data
}) {
  const allFaqs = []

  if (hasFaq !== undefined) {
    hasFaq.map(faq => {
      return allFaqs.push({
        "@type": "Question",
        name: faq.question.text,
        acceptedAnswer: {
          "@type": "Answer",
          text: faq.answer.html,
        },
      })
    })
  }

  const schemaFaq = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: allFaqs,
  }

  const isBlogPostPage = location.pathname.includes("/blog/") // Check if it's a blog post page

  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${shortDesc}`}
            link={
              canonical
                ? [{ rel: "canonical", key: canonical, href: canonical }]
                : []
            }
          >
            {loadClutch && (
              <script
                type="text/javascript"
                src="https://widget.clutch.co/static/js/widget.js"
              ></script>
            )}
            <script
              id="vtag-ai-js"
              src="https://r2.leadsy.ai/tag.js"
              data-pid="EuTAjOAkKSigaMms"
              data-version="062024"
              async
            />
            <meta
              name="google-site-verification"
              content="rQy_vMG6PfPhb1Ka0t7l042-j2mRL4YaiDWWc1nvLD8"
            />
            <meta name="format-detection" content="telephone=no" />
            <meta name="description" content={metaDescription} />
            <meta name="image" content={data.site.siteMetadata.image} />
            <meta
              property="og:url"
              content={`https://www.alpacked.io${location.pathname}`}
            />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={`${title} | ${shortDesc}`} />
            <meta property="og:description" content={metaDescription} />
            <meta
              property="og:image"
              content={
                image ||
                `${data.site.siteMetadata.siteUrl}${data.site.siteMetadata.image}`
              }
            />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            {isBlogPostPage && (
              <script type="application/ld+json">
                {`
                {
                  "@context": "https://schema.org",
                  "@type": "${isBlogPostPage ? "BlogPosting" : "WebPage"}",
                  "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": "https://alpacked.io${location.pathname}"
                  },
                  "headline": "${blogPostData?.data?.title.text || ""}",
                  "description": "${
                    blogPostData?.data?.seo_short_description?.text || ""
                  }",
                  "image": "${
                    blogPostData?.data?.image_link_preview?.fluid?.src || ""
                  }",
                  "author": {
                    "@type": "Person",
                    "name": "${
                      blogPostData?.data?.author?.document?.data?.name?.text ||
                      ""
                    }"
                  },
                  "publisher": {
                    "@type": "Organization",
                    "name": "Alpacked",
                    "logo": {
                      "@type": "ImageObject",
                      "url": "${
                        blogPostData?.data?.image_link_preview?.fluid?.src || ""
                      }"
                    }
                  },
                  "datePublished": "${
                    blogPostData?.first_publication_date || ""
                  }",
                  "dateModified": "${
                    blogPostData?.first_publication_date || ""
                  }",
                  "articleBody": "${blogPostData?.data?.body
                    ?.map(
                      item =>
                        item?.items?.map(content =>
                          striptags(content?.free_content?.html)
                        ) || ""
                    )
                    .join(" ")}"
                }
              `}
              </script>
            )}
            <script type="application/ld+json">
              {`
                {"@context":"http://schema.org",
                  "@type":"Organization",
                  "url":"https://alpacked.io",
                  "name":"Alpacked",
                  "sameAs":["https://www.linkedin.com/company/alpacked/",
                  "https://www.facebook.com/alpacked",
                  "https://github.com/Alpacked"]
                }
              `}
            </script>
            <script type="application/ld+json">
              {`
                {
                  "@context": "https://schema.org/",
                  "@type": "AggregateRating",
                  "itemReviewed": {
                    "@type": "Organization",
                    "image": "https://alpacked.io/static/12b0eaf923ef839f1b53079a5bf3a523/logo.svg",
                    "name": "Alpacked",
                    "telephone": "+380935427278",
                    "address" : {
                      "@type": "PostalAddress",
                      "streetAddress": "Vozdvyzhens'ka St, 51",
                      "addressLocality": "Kyiv",
                      "addressCountry": "Ukraine"
                    }
                  },
                  "ratingValue": "5",
                  "bestRating": "5",
                  "ratingCount": "6"
                }
              `}
            </script>
            <script type="application/ld+json">
              {`
                {
                  "@context":"http://schema.org",
                  "@type":"Organization",
                  "url":"https://alpacked.io",
                  "name":"Alpacked",
                  "logo":"https://alpacked.io/static/12b0eaf923ef839f1b53079a5bf3a523/logo.svg",
                  "email":"sales@alpacked.io",
                  "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Vozdvyzhens'ka St, 51",
                    "addressLocality": "Kyiv"
                  },
                  "telePhone": "+380935427278"
                }
              `}
            </script>
            {hasFaq !== undefined && (
              <script type="application/ld+json">
                {JSON.stringify(schemaFaq)}
              </script>
            )}
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  canonical: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  shortDesc: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  loadClutch: PropTypes.bool,
  blogPostData: PropTypes.object, // Added prop type for blog post data
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        image
      }
    }
  }
`
